span.help-block {
    font-size: 12px;
    color: red;
    font-weight: 100;
}

.nav-profile-name{  
    text-transform: capitalize;
}

.nav-profile {
    a{
        text-decoration: none;
    }
}
 
.font-weight-normal{ 
    font-weight: normal !important;
}

.font-weight-bold{ 
    font-weight: bold !important;
}

.capitalize{ 
    text-transform: capitalize;
}

div.dataTables_wrapper div.dataTables_info {
    padding-top: 15px;
}

.dataTables_wrapper .dataTables_length select { 
    padding: 16px 22px;
    background: #fff;
}

.page-link { 
    border-radius: 0 !important;
}

@media (min-width: 576px){

    .modal-dialog.modal-md {
        max-width: 800px; 
    }

}

.select{ 
    display: block;
    width: 100%;
    padding: 0.875rem 1.375rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 2px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
 
.form-control,
.btn{ 
    border-radius: 0 !important;
}

form.secure-key{
    button{ 
        height: 2.9063rem;
        padding: 15px;
        border: 0;
    }
}
.text-right{
    text-align: right;
}

.auth .auth-form-light select{
    color: #000;
    padding: 0.94rem 1.94rem;
    font-size: 1.25rem;
}


@media only screen and (max-width: 1199px) {

}

@media only screen and (max-width: 991px) {
    body{ 
        .register-form-btn{
            width: 100%;
        }
        .auth-form-head {
            h4, h3{
                padding-bottom: 0 !important;
                margin-bottom: 0 !important; 
                font-size: 17px;
            } 
            .brand-logo {
                margin-bottom: 0 !important;
                img {
                    width: auto;
                    height: 25px;
                }
            }
        }
        .auth-form-light{
            h4{
                font-size: 15px;
            } 
        }
    } 
}

@media only screen and (max-width: 767px) {
 
}

@media only screen and (max-width: 599px) {

}
